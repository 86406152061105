import PreviewCard from '@/modules/ProjectPreview-Tasks/components/TaskTabPreviewCard/TaskTabPreviewCard.vue'
import TaskParticipantsDialog from "../TaskParticipantsDialog/TaskParticipantsDialog.vue";
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import request from "@/services/axios_instance";
import { mapMutations } from "vuex";

export default {
    name: 'TaskViewDialog',
    components: {
        PreviewCard,
        TaskParticipantsDialog,
        CustomDialog
    },
    props: {
        task: Object,
        value: { type: Boolean, default: false }
    },
    data: () => ({
        dialog: false,
        delete_task_dialog: false,
        confirm_mark_as_complete_dialog: false,
        participant_dialog: false,
        componentKey: 0
    }),
    computed: {
        title(){
            return this.task && this.task.project ? this.task.project.title : '';
        }
    },
    watch: {
        value(val) {
            this.dialog = val
        },
        dialog(val) {
            this.$emit('input', val)
        },
        task: {
            handler() {
                this.force_render()
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        ...mapMutations('taskCards', ['replace_task']),
        force_render() {
            this.componentKey += 1
        },
        open_dialog() {
            this.dialog = true
        },
        close_dialog() {
            this.dialog = false
        },
        handle_dropdown_action(action) {
            const method = `open_${action}_task_dialog`
            this[method]()
        },
        open_delete_task_dialog() {
            this.$event.$emit('task-delete', this.task)
        },
        open_edit_task_dialog() {
            this.$event.$emit('task-edit', this.task)
        },
        open_mark_as_complete_task_dialog() {
            this.$event.$emit('task-mark-as-complete', this.task)
        },
        open_mark_as_urgent_task_dialog() {
            this.$event.$emit('task-mark-as-urgent', this.task)
        },
        manageTaskParticipants() {
            this.participant_dialog = true
        },
        syncAssignment(ids) {
            request.post(`api/task/${this.task.id}/sync-assignments`, { ids: ids })
                .then(({ data }) => {
                    this.replace_task(data)
                    this.force_render()
                    this.appSnackbar( 'Task assignments updated!');
                })
                .finally(() => this.participant_dialog = false)
        }
    }
}
