<template>
  <CustomDialog
    ref="dialog"
    button2-text="Save"
    :open.sync="open"
    :title="dialogTitle"
    @button1="close"
    @click-close="close"
    :max-width="800"
    @button2="save"
    :main-btn-disabled="isDisabled"
  >
    <template #content>
      <v-card flat class="ma-0">
        <v-card-text>
          <label for="title" class="popup-task-title">Title</label>
          <v-text-field
            outlined
            dense
            class="task-title-input"
            v-model="title"
            counter="60"
            color="#223A6B"
            hide-details="auto"
            prepend-inner-icon="mdi-alpha-t-circle-outline"
          ></v-text-field>
          <v-row>
            <v-col cols="6">
              <v-menu
                v-model="menu_start"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="started_at"
                    label="Start Date (optional)"
                    class="task-date-picker"
                    prepend-inner-icon="event"
                    v-bind="attrs"
                    hide-details
                    v-on="on"
                    outlined
                    @click:clear="end_at = null"
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  no-title
                  v-model="started_at"
                  @input="menu_start = false"
                />
              </v-menu>
            </v-col>
            <v-col cols="6">
              <v-menu
                v-model="menu_end"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                :disabled="!started_at"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="end_at"
                    class="task-end-date"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="!started_at"
                    @click:clear="end_at = null"
                    label="End Date (optional)"
                    prepend-inner-icon="event"
                    outlined
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  no-title
                  v-model="end_at"
                  :min="started_at"
                  @input="menu_end = false"
                />
              </v-menu>
            </v-col>
          </v-row>
          <Editor
            min-height="200"
            class="task-editor-input"
            placeholder="Task Description (optional)"
            v-model="description"
          />
          <v-row class="justify-center">
            <div class="caption">Will be automatically assigned to self</div>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </CustomDialog>
</template>

<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import Editor from '@/common/Editor/Editor.vue'
import moment from 'moment'
import request from '@/services/axios_instance'

export default {
  name: 'PersonalTaskDialog',
  components: {
    CustomDialog,
    Editor
  },
  props: {
    dialog: Boolean,
    task: Object,
    isEdit: { type: Boolean, default: false }
  },
  computed: {
    isDisabled() {
      return !this.title
    },
    dialogTitle() {
      return this.isEdit ? 'Edit Personal Task' : 'New Personal Task'
    }
  },
  data: () => ({
    open: false,
    started_at: null,
    end_at: null,
    title: null,
    menu_start: false,
    menu_end: false,
    description: null
  }),
  watch: {
    dialog: {
      handler(val) {
        this.open = val
      },
      immediate: true
    },
    started_at(val) {
      if (moment(val) > moment(this.end_at)) {
        this.end_at = null
      }
    },
    task: {
      handler(val) {
        if (this.isEdit) {
          this.title = val.title
          this.started_at = val.started_at
          this.end_at = val.end_at
          this.description = val.description
        }
      },
      immediate: true,
      deep: true
    },
    isEdit(val) {
      if (!val) this.clear()
    }
  },
  methods: {
    clear_and_close() {
      this.clear()
      this.close()
    },
    close() {
      this.open = false
      this.$emit('click-close')
    },
    clear() {
      this.title = null
      this.description = null
      this.started_at = null
      this.end_at = null
    },
    save() {
      if (!this.title) {
        this.appSnackbar('Title is required', 'error')
        return false
      }
      let payload = {
        title: this.title,
        end_at: this.end_at,
        started_at: this.started_at,
        description: this.description
      }
      if (!this.isEdit) {
        this.create(payload)
      } else {
        payload.id = this.task.id
        this.update(payload)
      }
    },
    create(payload) {
      request
        .post(`api/task/personal`, payload)
        .then(({ data }) => {
          this.appSnackbar('New task created')
          this.$emit('new-task-added', data)
        })
        .finally(() => this.clear_and_close())
    },
    update(payload) {
      request
        .put(`api/task/personal`, payload)
        .then(({ data }) => {
          this.appSnackbar('Task updated')
          this.$emit('task-updated', data)
        })
        .finally(() => this.clear_and_close())
    }
  }
}
</script>

<style scoped>
.caption {
  font-size: 14px;
  color: #223a6b;
  font-weight: 600;
}
label.popup-task-title {
  font-size: 16px;
  color: #223a6b;
  font-weight: 700;
}
.task-title-input >>> fieldset,
.task-date-picker >>> fieldset,
.task-end-date >>> fieldset,
.task-editor-input >>> fieldset {
  border-color: #223a6b !important;
  border-width: 1px;
}
.task-date-picker .v-label {
  color: red !important;
  background: red !important;
}
</style>
