import { render, staticRenderFns } from "./TaskTabPreviewCard.vue?vue&type=template&id=5fbbb658&scoped=true&"
import script from "./TaskTabPreviewCard.js?vue&type=script&lang=js&"
export * from "./TaskTabPreviewCard.js?vue&type=script&lang=js&"
import style0 from "./TaskTabPreviewCard.scss?vue&type=style&index=0&id=5fbbb658&lang=scss&scoped=true&"
import style1 from "./TaskTabPreviewCard.vue?vue&type=style&index=1&id=5fbbb658&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fbbb658",
  null
  
)

export default component.exports