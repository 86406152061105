<template>
    <v-col md="12" class="pa-0 ma-0">
        <div class="request-tab">
            <div class="request__chips text-xs-center">
                <v-chip @click="filter_request_by('all')" small class="mx-1"
                        :class="['request__chip', { active: activeChip.toLowerCase() === 'all' }]"
                >
                    <v-avatar left class="task__avatar">{{ requests_counter.all }}</v-avatar>
                    All
                </v-chip>
                <v-chip @click="filter_request_by('completed')" small class="mx-1"
                        :class="['request__chip', { active: activeChip.toLowerCase() === 'completed' }]"
                >
                    <v-avatar left class="task__avatar">{{ requests_counter.completed }}</v-avatar>
                    Completed
                </v-chip>
                <v-chip @click="filter_request_by('approved')" small class="mx-1"
                        :class="['request__chip', { active: activeChip.toLowerCase() === 'approved' }]"
                >
                    <v-avatar left class="task__avatar">{{ requests_counter.approved }}</v-avatar>
                    Approved
                </v-chip>
                <v-chip @click="filter_request_by('archived')" small class="mx-1"
                        :class="['request__chip', { active: activeChip.toLowerCase() === 'archived' }]"
                >
                    <v-avatar left class="task__avatar">{{ requests_counter.archived }}</v-avatar>
                    Archived
                </v-chip>
                <v-chip @click="filter_request_by('pending')" small
                        class="mx-1"
                        :class="['request__chip', { active: activeChip.toLowerCase() === 'pending' }]"
                >
                    <v-avatar left class="task__avatar">{{ requests_counter.pending }}</v-avatar>
                    Pending
                </v-chip>
            </div>
            <v-row no-gutters class="py-2">
                <v-col cols="9" class="subtitle-2 text-left pl-4">Title</v-col>
                <v-col cols="2" class="subtitle-2 text-left">Status</v-col>
                <v-col cols="1" class="subtitle-2 text-center">
                    <v-icon>mdi-menu</v-icon>
                </v-col>
            </v-row>
            <v-divider />
            <v-list dense>
                <draggable v-model="requestList" v-if="requestList.length > 0" scroll-sensitivity="600"
                           force-fallback="true" @change="requests_reordered"
                >
                    <template v-for="item in requests">
                        <v-list-item :key="item.id" :id="`request-${item.id}`" class="px-0 draggable-item">
                            <v-row no-gutters>
                                <v-col cols="9" class=" text-left pl-4">
                                    <avatar :size="25" :user="item.requester" icon-only>
                                        <span>{{ item.title | ucwords | truncate(30) }}</span>
                                    </avatar>
                                </v-col>
                                <v-col cols="2" class=" text-left">
                                    <v-chip color="green" v-if="item.status.toLowerCase() ==='completed'" outlined label
                                            small class="text-left fullwidth"
                                    >
                                        {{ item.status|ucwords }}
                                    </v-chip>
                                    <v-chip color="blue" v-else-if="item.status.toLowerCase() ==='approved'" outlined
                                            label small class="text-left fullwidth"
                                    >
                                        {{ item.status|ucwords }}
                                    </v-chip>
                                    <v-chip color="grey" v-else-if="item.status.toLowerCase() ==='archived'" outlined
                                            label small class="text-left fullwidth"
                                    >
                                        {{ item.status|ucwords }}
                                    </v-chip>
                                    <v-chip color="red" v-else-if="item.status.toLowerCase() ==='pending'" outlined
                                            label small class="text-left fullwidth"
                                    >
                                        {{ item.status|ucwords }}
                                    </v-chip>
                                </v-col>
                                <v-col cols="1" class="text-center">
                                    <v-menu offset-y left>
                                        <template v-slot:activator="{ on: menu }">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on: tooltip }">
                                                    <v-icon class="mx-auto" v-on="{ ...tooltip, ...menu }">
                                                        mdi-dots-horizontal-circle-outline
                                                    </v-icon>
                                                </template>
                                                <span>Actions</span>
                                            </v-tooltip>
                                        </template>
                                        <v-list dense>
                                            <v-list-item @click="request_view_action(item)">
                                                <v-list-item-title>
                                                    <v-icon color="grey" left>mdi-clipboard-text-play</v-icon>
                                                    View Request
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item
                                                    v-if="item.status.toLowerCase() !== 'completed' && can_edit_request(item)"
                                                    @click="request_edit_action(item, 'edit-request')"
                                            >
                                                <v-list-item-title>
                                                    <v-icon color="grey" left>
                                                        mdi-file-document-edit-outline
                                                    </v-icon>
                                                    Edit Request
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item
                                                    :disabled="item.histories.length === 0"
                                                    @click="request_history_action(item)"
                                            >
                                                <v-list-item-title>
                                                    <v-icon color="grey" left>mdi-history</v-icon>
                                                    Edit History
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item
                                                    v-if="item.status.toLowerCase() === 'approved' && (user.is_admin || user.is_manager)"
                                                    @click="request_status_action(item, 'request-mark-as-completed')"
                                            >
                                                <v-list-item-title>
                                                    <v-icon color="grey" left>mdi-clipboard-check</v-icon>
                                                    Mark as Completed
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item
                                                    v-if="item.status.toLowerCase() === 'pending' && (can_edit_request(item) || user.is_admin || user.is_manager)"
                                                    @click="request_status_action(item, 'request-mark-as-archived')"
                                            >
                                                <v-list-item-title>
                                                    <v-icon color="grey" left>mdi-clipboard-arrow-down</v-icon>
                                                    Archived Request
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item
                                                    v-if="item.status.toLowerCase() === 'pending' && (user.is_admin || user.is_manager)"
                                                    @click="request_status_action(item, 'request-mark-as-approved')"
                                            >
                                                <v-list-item-title>
                                                    <v-icon color="grey" left>mdi-clipboard-account</v-icon>
                                                    Approved Request
                                                </v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </v-col>
                            </v-row>
                        </v-list-item>
                    </template>
                </draggable>
                <v-list-item v-else>
                    <empty headline="No requests found!" class="mx-auto" />
                </v-list-item>
                <v-list-item class="loading" v-if="loading">
                    <v-progress-linear :indeterminate="true" />
                </v-list-item>
                <v-list-item v-if="hasLoadMoreButton">
                    <v-row>
                        <v-spacer />
                        <v-btn block text :disabled="disabled_load_more_request" @click="see_more_request">
                            View More
                        </v-btn>
                        <v-spacer />
                    </v-row>
                </v-list-item>
            </v-list>
        </div>
        <RequestViewDialog
                :dialog="view_request_dialog"
                :item="activeRequest"
                @close="view_request_dialog = false"
                @request-mark-as-archived="request_status_action(activeRequest,'request-mark-as-archived')"
                @request-mark-as-completed="request_status_action(activeRequest,'request-mark-as-completed')"
                @request-mark-as-approved="request_status_action(activeRequest,'request-mark-as-approved')"
                @request-edit="request_edit_action(activeRequest, 'edit-request')"
        />
        <ConfirmDialog :open-dialog.sync="confirm_dialog"
                        title="Confirmation required!"
                        confirm-button-text="Yes"
                        @confirm="confirmed"
                        :text-content="confirm_question"
        />
        <RequestEditHistoryDialog :dialog="view_request_history_dialog"
                                     :item="activeRequest"
                                     @close="view_request_history_dialog = false"
        />
    </v-col>
</template>

<script>

    import {mapActions, mapGetters, mapMutations} from "vuex";
    import apiTo from '@/modules/ProjectPreview-Tasks/api'

    import draggable from 'vuedraggable'
    import RequestViewDialog from "@/modules/ProjectPreview-Tasks/components/RequestViewDialog/RequestViewDialog.vue";
    import ConfirmDialog from '@/common/ConfirmDialog.vue'
    import RequestEditHistoryDialog
        from "@/modules/ProjectPreview-Tasks/components/RequestViewDialog/RequestEditHistoryDialog.vue";

    export default {
        name: "RequestTab",
        props: {
            hasLoadMoreButton: {type: Boolean, default: true}
        },
        components: {
            RequestEditHistoryDialog,
            ConfirmDialog,
            RequestViewDialog,
            draggable
        },
        data: () => ({
            activeChip: 'all',
            view_request_dialog: false,
            activeRequest: null,
            activeAction: null,
            edittingRequest: false,
            confirm_dialog: false,
            view_request_history_dialog: false,
            confirm_question: 'Proceed ?'
        }),
        created() {
            this.get_requests_counter()
            this.get_requests()
        },
        computed: {
            ...mapGetters('taskCards', ['requests', 'loading', 'requests_counter', 'id', 'see_more_request_url']),
            ...mapGetters(['user']),
            requestList: {
                get() {
                    return this.requests
                },
                set(value) {
                    this.set_requests(value)
                }
            },
            disabled_load_more_request() {
                return !this.see_more_request_url
            }
        },
        methods: {
            ...mapMutations('taskCards', ['set_requests', 'replace_request', 'add_request']),
            ...mapActions('taskCards', ['get_requests', 'get_requests_counter', 'see_more_request']),
            can_edit_request(item) {
                return !!(item && this.user.id === item.requested_by)
            },
            confirmed() {
                let payload = {
                    status: this.activeAction.replace('request-mark-as-', '').trim().toLowerCase()
                }
                apiTo.re_status_request(this.activeRequest.id, payload)
                    .then(({data}) => {
                        this.replace_request(data)
                        this.activeRequest = data
                        this.appSnackbar( 'Request updated')
                    })
                    .finally(() => {
                        this.confirm_dialog = false
                        this.confirm_question = 'Proceed?'
                    })
            },
            request_status_action(item, action) {
                this.activeRequest = item
                this.activeAction = action
                if (action === 'request-mark-as-completed') {
                    this.confirm_question = 'Mark request as completed?'
                } else if (action === 'request-mark-as-archived') {
                    this.confirm_question = 'Archived this request?'
                } else if (action === 'request-mark-as-approved') {
                    this.confirm_question = 'Approved this request?'
                }
                this.confirm_dialog = true
            },
            request_edit_action(item, action) {
                this.activeRequest = item
                this.activeAction = null
                this.$event.$emit('request-edit', item)
            },
            request_view_action(item) {
                this.activeRequest = item
                this.view_request_dialog = true
            },
            filter_request_by(filter) {
                this.activeChip = filter
                this.get_requests(filter)
            },
            requests_reordered() {
                let payload = {
                    order: this.requests.map(item => {
                        return item.id
                    })
                }
                apiTo.reorder_request(payload)
                    .then(({data}) => {
                        this.appSnackbar( 'Request reordered')
                    })
            },
            request_history_action(item) {
                this.activeRequest = item
                this.view_request_history_dialog = true
            }
        }
    }
</script>

<style lang="scss" scoped>
     

    .request__chips {
        padding: 10px 0;
        border-bottom: 1px solid $borderGray;
        text-align: center;

        .theme--light.v-chip {
            background-color: $white;
            border: 1px solid $borderGray;
            font-size: 12px;
            font-weight: 500;
            color: $chipGray;
            z-index: 0;
        }

        .task__avatar {
            background-color: $chipGray;
            border-color: $chipGray;
            color: $white;
            font-size: 12px;
            font-weight: 500;
            left: -6px;
        }

        .request__chip.active {
            background-color: $chipDark;
            border-color: $chipDark;
            color: $white;

            .task__avatar {
                background-color: $chipBlue;
                border-color: $chipBlue;
            }
        }
    }
</style>
<style scoped>
    >>> .request__chip .v-chip__content {
        cursor: pointer;
    }

    >>> .draggable-item {
        border-bottom: 1px solid #dbe1e5;
        cursor: grab;
    }
</style>