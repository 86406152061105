<template>
  <v-menu
    ref="menu"
    v-model="menu"
    offset-y
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="dateFormatted"
        :prepend-icon="prependIcon"
        readonly
        outlined
        hide-details="auto"
        dense
        :disabled="disabled"
        @click:clear="date = null"
        clear-icon="mdi-close-circle-outline"
        :clearable="clearable"
        :rules="required ? [requiredRule()] : []"
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      :min="minDate"
      :max="maxDate"
      @input="handleInput"
      :no-title="!hasTitle"
      scrollable
    >
      <v-row
        dense
        v-if="!autoCloseOnSelect"
        align="center"
        class="pb-2"
        justify="end"
      >
        <v-btn
          depressed
          class="mx-1 text-none"
          outlined
          color="primary"
          @click="menu = false"
        >
          Cancel
        </v-btn>
        <v-btn
          depressed
          :disabled="!date"
          class="mx-1 text-none"
          color="primary"
          @click="$refs.menu.save(date)"
        >
          Set Date
        </v-btn>
      </v-row>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: 'KirbyDatePicker',
  props: {
    autoCloseOnSelect: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    clearable: { type: Boolean, default: true },
    hasTitle: { type: Boolean, default: false },
    value: { type: String, default: null },
    minDate: { type: String, default: null },
    maxDate: { type: String, default: null },
    prependIcon: { type: String, default: null }
  },
  data: () => ({
    date: null,
    menu: false
  }),
  watch: {
    value: {
      handler: function (val) {
        this.date = val
      },
      immediate: true
    },
    date: {
      handler: function (val) {
        this.$emit('input', val)
      },
      immediate: true
    }
  },
  computed: {
    dateFormatted() {
      return this.date ? this.moment(this.date).format('LL') : ''
    }
  },
  methods: {
    handleInput(date) {
      if (this.autoCloseOnSelect) this.$refs.menu.save(date)
    }
  }
}
</script>

<style></style>
