var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"task-custom-table"},[_c('v-row',{staticClass:"task_header",attrs:{"no-gutters":""}},[(!_vm.is_my_task_tab)?_c('v-col',{staticClass:"task__tableHead",attrs:{"cols":"2"}},[_vm._v(" Assigned ")]):_vm._e(),_c('v-col',{staticClass:"task__tableHead",attrs:{"cols":_vm.is_my_task_tab ? 8 : 6}},[_vm._v("Task")]),_c('v-col',{staticClass:"task__tableHead text-center",attrs:{"cols":"2"}},[_vm._v("Status")]),_c('v-col',{staticClass:"task__tableHead text-center",attrs:{"cols":"2"}},[_vm._v("Action")])],1),_c('div',{staticClass:"task_body",style:({ height: _vm.bodyMaxHeight })},[(_vm.tasks.length > 0)?_c('v-list',{attrs:{"dense":""}},[_vm._l((_vm.tasks),function(task){return [_c('v-list-item',{key:task.id,staticClass:"px-0"},[_c('v-list-item-content',{staticClass:"pa-0"},[_c('v-list-item-title',[_c('v-row',{class:[
                  'task__tableBody',
                  { active: task.id === _vm.active_task_id }
                ],attrs:{"no-gutters":""},on:{"click":function($event){$event.stopPropagation();return _vm.task_view_action(task)}}},[(!_vm.is_my_task_tab)?_c('v-col',{staticClass:"assignee__col",attrs:{"md":"2"}},[(task.assigned.length > 0)?_c('Avatars',{staticStyle:{"display":"inline-block"},attrs:{"deep":false,"items":task.assigned,"count":1}}):_c('v-avatar',{attrs:{"size":"40"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-account-question-outline")])],1)],1):_vm._e(),_c('v-col',{staticClass:"project__col",attrs:{"md":_vm.is_my_task_tab ? 8 : 6}},[_c('p',{staticClass:"subtitle-2"},[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm._f("ucwords")(task.title),35))),_c('br'),(_vm.showProject && task.project)?_c('router-link',{attrs:{"to":_vm.get_project_url(task.project)},nativeOn:{"click":function($event){return $event.stopImmediatePropagation()}}},[_c('small',{staticClass:"caption"},[_vm._v(_vm._s(_vm._f("truncate")(_vm._f("ucwords")(task.project.title),20))+" | ")])]):_vm._e()],1)]),_c('v-col',{staticClass:"status__col text-center",attrs:{"md":"2","sm":"2"}},[(task.status.toLowerCase() === 'completed')?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-clipboard-check-outline ")]):(task.status.toLowerCase() === 'pending')?_c('v-icon',{attrs:{"color":"warning"}},[_vm._v(" mdi-clipboard-pulse-outline ")]):(task.status.toLowerCase() === 'behind')?_c('v-icon',{attrs:{"color":"danger"}},[_vm._v(" mdi-clipboard-arrow-left-outline ")]):(task.status.toLowerCase() === 'open')?_c('v-icon',{attrs:{"color":"info"}},[_vm._v(" mdi-clipboard-text-play-outline ")]):(task.status.toLowerCase() === 'urgent')?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-clipboard-alert-outline ")]):_vm._e(),_c('br'),_vm._v(" "+_vm._s(_vm._f("ucwords")(task.status))+" ")],1),_c('v-col',{staticClass:"action__col",attrs:{"md":"2","sm":"2"}},[_c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-2",attrs:{"color":"grey","dark":"","icon":""}},Object.assign({}, tooltip, menu)),[_c('v-icon',{attrs:{"color":"#223A6B"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('span',[_vm._v("Actions")])])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[(
                          task.status.toLowerCase() !== 'completed' &&
                          _vm.can_edit_task
                        )?_c('v-list-item',{on:{"click":function($event){return _vm.task_action(task, 'task-edit')}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"color":"grey","left":""}},[_vm._v(" mdi-file-document-edit-outline ")]),_vm._v(" Edit Task ")],1)],1):_vm._e(),(_vm.can_delete_task)?_c('v-list-item',{on:{"click":function($event){return _vm.task_action(task, 'task-delete')}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"color":"grey","left":""}},[_vm._v(" mdi-delete-alert-outline ")]),_vm._v(" Delete Task ")],1)],1):_vm._e(),(_vm.can_view_task)?_c('v-list-item',{on:{"click":function($event){return _vm.task_view_action(task)}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"color":"grey","left":""}},[_vm._v(" mdi-clipboard-text-play ")]),_vm._v(" View Task ")],1)],1):_vm._e(),(
                          task.status.toLowerCase() !== 'completed' &&
                          _vm.can_edit_task
                        )?_c('v-list-item',{on:{"click":function($event){return _vm.task_action(task, 'task-mark-as-complete')}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"color":"grey","left":""}},[_vm._v(" mdi-clipboard-check ")]),_vm._v(" Mark as Complete ")],1)],1):_vm._e(),(
                          task.status.toLowerCase() !== 'completed' &&
                          _vm.can_edit_task
                        )?_c('v-list-item',{on:{"click":function($event){return _vm.task_action(task, 'task-mark-as-urgent')}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"color":"grey","left":""}},[_vm._v(" mdi-clipboard-alert-outline ")]),_vm._v(" "+_vm._s(task.status.toLowerCase() === 'urgent' ? "Mark as Non-urgent" : "Mark as Urgent")+" ")],1)],1):_vm._e()],1)],1)],1)],1)],1)],1)],1)]})],2):_c('Empty',{attrs:{"icon":"mdi-clipboard-list-outline","headline":"No task found!"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }