<template>
  <CustomDialog
    v-model="dialog"
    @button1="close"
    :title="title"
    :button2-text="saveButtonLabel"
    @button2="save"
    :main-btn-disabled="!valid"
    :max-width="800"
  >
    <template v-slot:content>
      <v-card flat color="transparent">
        <v-card-text>
          <v-row dense>
            <v-col :cols="!hasMilestones ? 12 : mdAndUp ? 6 : 12">
              <label class="primary--text subtitle-2">Task Title: </label>
              <v-text-field
                v-model="mapping.title"
                outlined
                hide-details="auto"
                dense
                :rules="[requiredRule()]"
              ></v-text-field>
            </v-col>
            <v-col :cols="mdAndUp ? 6 : 12" v-if="hasMilestones">
              <label class="primary--text subtitle-2">
                Attach to Milestone:
              </label>
              <v-select
                v-model="mapping.milestone_id"
                :items="milestones"
                item-text="title"
                item-value="id"
                outlined
                hide-details="auto"
                dense
                clearable
                clear-icon="mdi-close-circle-outline"
                @click:append="$emit('create-milestone')"
                append-icon="mdi-plus-circle-outline"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title> No milestone yet </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col :cols="mdAndUp ? 6 : 12">
              <label class="primary--text subtitle-2">Task Start Date: </label>
              <KirbyDatePicker
                :required="false"
                clearable
                :min-date="today"
                auto-close-on-select
                v-model="mapping.started_at"
              ></KirbyDatePicker>
            </v-col>
            <v-col :cols="mdAndUp ? 6 : 12">
              <label class="primary--text subtitle-2">Task End Date:</label>
              <KirbyDatePicker
                :disabled="!mapping.started_at"
                :min-date="mapping.started_at"
                :required="false"
                clearable
                auto-close-on-select
                v-model="mapping.end_at"
              ></KirbyDatePicker>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" v-if="hasAssigning">
              <label class="primary--text subtitle-2">Assign to: </label>
              <KirbyUserPicker
                outlined
                dense
                :items="project_members"
                clearable
                has-add
                :required-count="0"
                v-model="mapping.assigned"
              ></KirbyUserPicker>
            </v-col>
            <v-col cols="12">
              <label class="primary--text subtitle-2">
                Attach to Milestone:
              </label>
              <Editor v-model="mapping.description" min-height="150"></Editor>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </CustomDialog>
</template>
<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import KirbyDatePicker from '@/common/Pickers/KirbyDatePicker.vue'
import KirbyUserPicker from '@/common/Pickers/KirbyUserPicker.vue'
import Editor from '@/common/Editor/Editor.vue'
import moment from 'moment'
import _ from 'lodash'
import { mapGetters } from 'vuex'
export default {
  name: 'TaskDialog',
  components: {
    CustomDialog,
    Editor,
    KirbyDatePicker,
    KirbyUserPicker
  },
  props: {
    value: { type: Boolean, default: false },
    hasMilestones: { type: Boolean, default: true },
    hasAssigning: { type: Boolean, default: true },
    item: { type: Object },
    milestone: { type: Object },
    milestones: Array,
    projectId: { type: [Number, String], default: null },
    userId: { type: [Number, String], default: null }
  },
  computed: {
    ...mapGetters(['user']),
    ...mapGetters('project', ['project_members']),
    isEdit() {
      return this.item ? true : false
    },
    saveButtonLabel() {
      return this.isEdit ? 'Update' : 'Save'
    },
    title() {
      return !this.isEdit ? 'Create Task' : 'Update Task'
    },
    valid() {
      return this.mapping.title
    },
    today() {
      return this.moment().format('YYYY-MM-DD')
    }
  },
  data: () => ({
    dialog: false,
    mapping: {
      title: null,
      started_at: null,
      end_at: null,
      milestone_id: null,
      description: null,
      assigned: [],
      project_id: null
    },
    reset: {
      title: null,
      started_at: null,
      end_at: null,
      milestone_id: null,
      description: null,
      assigned: []
    }
  }),
  watch: {
    userId: {
      handler: function (val) {
        if (val) {
          this.mapping.assigned.push(val)
        }
      },
      immediate: true
    },
    milestone: {
      handler: function (val) {
        if (val && val.hasOwnProperty('id')) {
          this.mapping.milestone_id = val.id
        }
      },
      immediate: true,
      deep: true
    },
    hasAssigning: {
      handler: function (val) {
        if (!val) {
          this.mapping.assigned = [this.user.id]
        }
      },
      immediate: true
    },
    dialog(val) {
      this.$emit('input', val)
    },
    value(val) {
      this.dialog = val
      if (val && !this.isEdit) {
        this.mapping.title = null
        this.mapping.started_at = null
        this.mapping.end_at = null
        this.mapping.milestone_id = this.milestone ? this.milestone.id : null
        this.mapping.description = null
        this.mapping.assigned = !this.hasAssigning ? [this.user.id] : []
      }
    },
    projectId: {
      handler: function (val) {
        this.mapping.project_id = val ? Number(val) : null
      },
      immediate: true
    },
    item: {
      handler: function (val) {
        if (val) {
          this.mapping = {
            project_id: this.projectId || null,
            title: val.title,
            started_at: val.started_at,
            end_at: val.end_at,
            milestone_id: val.milestone_id,
            description: val.description,
            assigned: val.assigned.map((u) => u.id)
          }
        } else {
          this.mapping = _.cloneDeep(this.reset)
          if (this.userId) {
            this.mapping.assigned.push(this.userId)
          }
          if (this.projectId) {
            this.mapping.project_id = this.projectId
          }
        }
      },
      deep: true,
      immediate: true
    },
    'mapping.started_at': {
      handler: function (val) {
        if (!val) {
          this.mapping.end_at = null
        }
        if (
          val &&
          this.mapping.end_at &&
          moment(this.mapping.end_at) < moment(val)
        ) {
          this.mapping.end_at = null
        }
      }
    }
  },
  methods: {
    close() {
      this.dialog = false
      this.mapping = Object.assign({}, this.mapping, this.reset)
    },
    save() {
      if (this.valid) {
        this.$emit(this.isEdit ? 'update' : 'save', this.mapping)
      } else {
        this.appSnackbar('Please fill in required fields', 'error')
      }
    }
  }
}
</script>
<style></style>
