<template>
    <div class="task-dialog">
        <CustomDialog ref="dialog" :open.sync="dialog" :title="dialogTitle" @button1="clear_and_close"
                       :max-width="800"
        >
            <template #content>
                <v-row wrap class="custom-dialog">
                    <v-col cols="6" class="pt-0">
                        <v-select :aria-required="true" :items="templates" label="Select Form From Template *"
                                  filled hide-details
                                  item-text="name" prepend-inner-icon="mdi-clipboard-outline" return-object
                                  append-outer-icon=" mdi-plus-circle-outline"
                                  v-model="request_template"
                                  @click:append-outer="$router.push({name: 'request-builder-create'})"
                        >
                            <template v-slot:no-data>
                                <v-alert class="mx-2" color="cyan" colored-border>
                                    No request template yet.
                                </v-alert>
                            </template>
                            <template v-slot:item="data">
                                <template>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ data.item.name |ucwords }}</v-list-item-title>
                                    </v-list-item-content>
                                </template>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col v-if="!projectId" :cols="6" class="pt-0">
                        <v-select v-model="project_id" :items="projects" item-text="title"
                                  item-value="id" label="Project | Campaign (Optional)"
                                  placeholder="Select Project | Campaign"
                                  filled
                                  hide-details class="d-field" dense clearable
                                  prepend-inner-icon="mdi-alpha-p-circle-outline"
                        >
                            <template v-slot:item="data">
                                <template>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ data.item.title|ucwords }}</v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{ data.item.props.business_name|ucwords }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col :cols="projectId ? 6 : 12" class="pt-0">
                        <v-text-field class="dialog__textfield d-field" label="Add Request Title *" v-model.trim="title"
                                      filled hide-details color="#657186" dense
                                      prepend-inner-icon="mdi-alpha-t-circle-outline"
                        />
                    </v-col>
                    <v-col cols="12" class="pt-0">
                        <FillUp :key="keyy" :structures="structures" v-model="structures" />
                        <Empty headline="Select a template or create one first" icon="mdi-clipboard-outline"
                               v-if="!hasStructures"
                        />
                    </v-col>
                </v-row>
            </template>
            <template slot="button2">
                <v-btn text class="text-none" outlined @click="save" :disabled="disabled" :loading="btnloading">
                    Save
                </v-btn>
            </template>
        </CustomDialog>
        <ConfirmDialog :open-dialog.sync="confirm_dialog"
                        title="Confirmation required!"
                        confirm-button-text="Yes"
                        @confirm="confirmed"
                        text-content="Updating an already approved request will reset its status back to pending for approval. Proceed? "
        />
    </div>
</template>
<script>
    import CustomDialog from "@/common/BaseComponents/CustomDialog/CustomDialog.vue";
    import FillUp from "@/modules/Forms-Builder/components/Builder/FillUp.vue";
    import ConfirmDialog from '@/common/ConfirmDialog.vue'
    import apiTo from '@/modules/ProjectPreview-Tasks/api'
    import _cloneDeep from 'lodash/cloneDeep'

    export default {
        name: 'RequestDialog',
        components: {
            FillUp,
            CustomDialog,
            ConfirmDialog
        },
        props: {
            projectId: [Number, String],
        },
        data: () => ({
            dialog: false,
            isEdit: false,
            title: '',
            description: null,
            item: null,
            btnloading: false,
            confirm_dialog: false,
            projects: [],
            project_id: null,
            request_template: null,
            templates: [],
            datus: [],
            keyy: 1,
        }),
        computed: {
            disabled() {
                return this.validate(false) === false
            },
            dialogTitle() {
                return this.isEdit ? 'Edit Request' : 'Add Request'
            },
            structures: {
                get() {
                    return this.datus
                },
                set(val) {
                    this.datus = val
                }
            },
            hasStructures() {
                return this.structures.length > 0
            }

        },
        created() {
            this.get_projectList()
            this.getTemplates()
        },
        watch: {
            projectId(val) {
                this.project_id = val
            },
            request_template: {
                handler(val) {
                    if (val) {
                        this.structures = _cloneDeep(val.meta.template.value)
                    }
                },
                deep: true,
                immediate: true
            }
        },
        methods: {
            validate(withMessage) {
                if (!this.isEdit && !this.request_template) {
                    return false
                }
                if (!this.title) {
                    if (withMessage)
                        this.appSnackbar( 'Report title is required', 'error');
                    return false
                }
                for (let i = 0; i < this.structures.length; i++) {
                    if (this.structures[i].required && (!this.structures[i].value || this.structures[i].value.trim() === '')) {
                        if (withMessage)
                            this.appSnackbar( 'Fields with * are required', 'error');
                        return false
                    }
                }
                return true
            },
            getTemplates() {
                apiTo.template_list()
                    .then(({data}) => {
                        this.templates = data
                    })
            },
            get_projectList() {
                apiTo.project_list()
                    .then(({data}) => {
                        this.projects = data
                    })
            },
            open_dialog(item) {
                this.dialog = true
                this.isEdit = false
                this.btnloading = false
                this.item = null
                this.request_template = null
                if (item) {
                    this.isEdit = true
                    this.item = item
                    this.description = item.contents
                    this.title = item.title
                    this.project_id = item.project_id
                    this.structures = item.props.structures || []
                    this.keyy++
                } else {
                    this.item = null
                    this.description = null
                    this.title = null
                    this.structures = []
                    this.project_id = this.projectId
                }
            },
            clear_and_close() {
                this.dialog = false
                this.title = ''
                this.description = null
                this.structures = []
                this.confirm_dialog = false
                this.item = null
            },
            confirmed() {
                this.btnloading = true
                this.confirm_dialog = false
                this.$emit('updated', {
                    id: this.item.id,
                    title: this.title,
                    structures: this.structures,
                    project_id: this.item.project_id
                })
            },
            save() {
                if (this.disabled) return
                let payload = {
                    title: this.title,
                    structures: this.structures,
                    project_id: this.project_id
                }
                if (this.isEdit && this.item.status.toLowerCase() !== 'approved') {
                    this.btnloading = true
                    payload.id = this.item.id
                    this.$emit('updated', payload)
                } else if (this.isEdit && this.item.status.toLowerCase() === 'approved') {
                    this.confirm_dialog = true
                } else if (!this.isEdit) {
                    this.btnloading = true
                    this.$emit('saved', payload)
                }
            }
        }
    }
</script>