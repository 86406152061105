import { mapActions, mapGetters, mapMutations } from "vuex";

export const task_mixins = {
    data: () => ({
        activeMilestone: null,
        task_dialog: false,
        delete_task_dialog: false,
        create_milestone_dialog: false
    }),
    watch: {
        activeTask: {
            handler: function (val) {
                this.$emit("show-task", val);
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        ...mapGetters("taskCards", ["taskActions", 'activeTask', "milestones"]),
    },
    methods: {
        ...mapActions('taskCards', ['saveMilestone', 'updateTaskStatus']),
        ...mapMutations('taskCards', ['set_activeTask']),
        addNewMilestone(payload) {
            this.saveMilestone({
                payload: payload,
                cb: (data) => {
                    this.activeMilestone = data;
                    this.create_milestone_dialog = false;
                    this.appSnackbar( "Milestone created");
                },
            });
        },
        setActiveTask(task) {
            this.set_activeTask(task);
        },
        createTask() {
            this.task_dialog = true;
            this.activeTask = null;
        },
        saveTask(payload) {
            this.$store.dispatch("taskCards/saveTask", {
                type: this.private ? "personal" : "project",
                payload: payload,
                cb: (data) => {
                    this.task_dialog = false;
                    this.appSnackbar( "Task created.");
                },
            });
        },
        updateTask(payload) {
            this.$store.dispatch("taskCards/updateTask", {
                taskId: this.activeTask.id,
                type: this.private ? "personal" : "project",
                payload: payload,
                cb: (data) => {
                    this.task_dialog = false;
                    this.setActiveTask(data);
                    this.appSnackbar( "Task updated.");
                },
            });
        },
        deleteTask() {
            this.$store.dispatch("taskCards/deleteTask", {
                taskId: this.activeTask.id,
                cb: (data) => {
                    this.delete_task_dialog = false;
                    this.setActiveTask(null);
                    this.appSnackbar( "Task deleted.");
                },
            });
        },
        handleTaskAction(action, task) {
            this.activeTask = task;
            if (["mark-as-complete", "mark-as-urgent"].includes(action.value)) {
                this.updateTaskStatus({
                    taskId: task.id,
                    action: action.value,
                    payload: {
                        notify_complete: true,
                    },
                    cb: (data) => {
                        this.appSnackbar( "Task status updated.");
                    },
                });
            } else if (action.value == "edit") {
                this.task_dialog = true;
            } else if (action.value == "delete") {
                this.delete_task_dialog = true;
            } else if (action.value == "go-to-project") {
                this.$router.push(action.route).catch(() => { })
            }
        },
    }
}