import DatePicker from '@/common/DatePicker.vue'
import moment from 'moment'
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'

export default {
    name: 'MilestoneTabDialog',
    components: {
        DatePicker,
        CustomDialog
    },

    props: {
        value: { type: Boolean, default: false },
        item: Object
    },

    data: () => ({
        open: false,
        title: null,
        start_date: null,
        end_date: null,
        status: 'active',
        days_init_value: 0,
        btnloading: false
    }),
    created() {
        this.$event.$on('btnloading_off', () => {
            this.btnloading = false
        })
    },
    computed: {
        disabled() {
            return !this.title
        },
        isEdit() {
            return this.item ? true : false;
        },
        dialogTitle() {
            return this.isEdit ? 'Update Milestone' : "Create Milestone"
        },
        days: {
            get: function () {
                if (!this.start_date || !this.end_date) return this.days_init_value
                return moment(this.end_date).diff(moment(this.start_date), 'days')
            },
            set: function (newValue) {
                if (!this.start_date && !this.end_date) {
                    this.days_init_value = newValue
                    return
                }
                if (!this.start_date) {
                    this.start_date = moment(this.end_date)
                        .subtract(newValue, 'days')
                        .format('YYYY-MM-DD')
                } else {
                    this.end_date = moment(this.start_date)
                        .add(newValue, 'days')
                        .format('YYYY-MM-DD')
                }

                this.days_init_value = newValue
            }
        }
    },
    watch: {
        value(val) {
            this.open = val
            if (val) {
                this.btnloading = false
            }
        },
        open(val) {
            this.$emit('input', val)
        },
        item: {
            handler(new_val) {
                if (new_val) this.update_fields(new_val)
            },
            deep: true,
            immediate: true
        }
    },

    methods: {
        end_date_changed(val) {
            this.end_date = val
            if (this.days && val) {
                this.start_date = moment(val)
                    .subtract(this.days, 'days')
                    .format('YYYY-MM-DD')
            }
        },

        start_date_changed(val) {
            this.start_date = val
            if (this.days && val) {
                this.end_date = moment(val)
                    .add(this.days, 'days')
                    .format('YYYY-MM-DD')
            }
            if (!val || (moment(val) > moment(this.end_date))) {
                this.end_date = null
            }
        },

        cancel() {
            this.open = false
            Object.assign(this.$data, this.$options.data.apply(this))
        },

        save() {
            const fields_to_save = {
                title: this.title,
                status: this.status,
                days: this.days,
                started_at: this.start_date,
                end_at: this.end_date
            }
            this.$emit(this.isEdit ? 'update' : 'save', fields_to_save)
        },

        update_fields(item) {
            this.title = item.title
            this.status = item.status
            this.days = item.days
            this.start_date = item.started_at
            this.end_date = item.end_at
        }
    }
}