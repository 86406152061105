<template>
  <CustomDialog
    :max-width="800"
    :open.sync="open"
    title="Task Assignment"
    @click-close="close"
    button2-text="Save"
    :main-btn-disabled="disabled"
    @button2="save"
  >
    <template v-slot:content>
      <v-card flat>
        <v-card-text v-if="item">
          <v-row dense>
            <v-col cols="12">
              Assign task for <strong>{{ item.title | ucwords }}</strong>
            </v-col>
            <v-col cols="6">
              <v-list dense max-height="400" style="overflow-y: auto">
                <v-subheader class="subtitle-2">
                  <span class="mr-1" v-if="item.project_id">
                    {{ item.project.type | ucwords }}
                  </span>
                  &nbsp;Members ({{ members.items.length }})
                </v-subheader>
                <template v-if="available.length > 0">
                  <v-list-item
                    v-for="(user, index) in available"
                    :key="index"
                    @click="add_to(user, index)"
                  >
                    <v-list-item-avatar>
                      <v-img
                        :lazy-src="settings.loader"
                        :src="user.image_url"
                      />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="primary--text">
                        {{ user.fullname | ucwords }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ user.job_title | ucwords }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon>mdi-account-arrow-right-outline</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </template>
              </v-list>
            </v-col>
            <v-col cols="6">
              <v-list dense max-height="400" style="overflow-y: auto">
                <v-subheader class="subtitle-2">
                  Selected ({{ members.selected.length }})
                </v-subheader>
                <template v-if="members.selected.length > 0">
                  <v-list-item
                    v-for="(user, index) in members.selected"
                    :key="index"
                    @click="remove_from(user, index)"
                  >
                    <v-list-item-avatar>
                      <v-img
                        :lazy-src="settings.loader"
                        :src="user.image_url"
                      />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="primary--text">
                        {{ user.fullname | ucwords }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ user.job_title | ucwords }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon>mdi-account-arrow-left-outline</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </template>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </CustomDialog>
</template>

<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import _cloneDeep from 'lodash/cloneDeep'
import _diffBy from 'lodash/differenceBy'
import request from '@/services/axios_instance'
import { mapGetters } from 'vuex'

export default {
  name: 'TaskParticipantsDialog',
  components: {
    CustomDialog
  },
  props: {
    id: [String, Number],
    task: Object,
    dialog: { type: Boolean, default: false }
  },
  data: () => ({
    open: false,
    item: null,
    members: {
      items: [],
      original: [],
      selected: []
    }
  }),
  watch: {
    id(val) {
      if (val && !this.task) {
        this.getTaskById(val)
      }
    },
    dialog(val) {
      this.open = val
    },
    open(new_val) {
      this.$emit('update:dialog', new_val)
    },
    task: {
      handler(val) {
        this.item = val
        if (val) {
          this.getParticipants(val)
          this.members.original = _cloneDeep(val.assigned)
          this.members.selected = _cloneDeep(val.assigned)
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['users']),
    available() {
      return _diffBy(this.members.items, this.members.selected, 'id')
    },
    disabled() {
      return this.members.selected.length === 0
    }
  },
  methods: {
    close() {
      this.open = false
      this.$emit('close', false)
    },
    getParticipants(task) {
      if (task.project_id) {
        this.getParticipantByProject(task.project_id)
      } else this.members.items = this.users
    },
    getParticipantByProject(project_id) {
      request
        .get(`api/projects/${project_id}/member?all=true`)
        .then(({ data }) => {
          this.members.items = data
        })
    },
    getTaskById(id) {
      request.get(`api/task/${id}`).then(({ data }) => {
        this.item = data
        this.getParticipants(data)
        this.members.original = _cloneDeep(data.assigned)
        this.members.selected = _cloneDeep(data.assigned)
      })
    },
    add_to(user, index) {
      this.members.selected.push(user)
    },
    remove_from(user, index) {
      this.members.selected.splice(index, 1)
    },
    save() {
      this.$emit(
        'save',
        this.members.selected.map((item) => {
          return item.id
        })
      )
    }
  }
}
</script>

<style scoped></style>
