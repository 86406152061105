<template>
  <div
    v-bind="$attrs"
    class="hours__box d-flex align-center justify-space-between px-5"
  >
    <!-- <HelpToolTip :text="time_running"></HelpToolTip> -->
    <Avatar v-if="causer" :user="causer" :display="causer.first_name"></Avatar>
    <v-spacer />
    <!-- // eslint-disable-next-line vue/no-parsing-error -->
    <div class="d-flex align-center justify-center">
      <span class="fw-700 fs-16 primary--text">
        {{ isLessTen(hours) ? `0${hours}` : hours }}
      </span>
      <span class="fw-700 fs-16 primary--text mx-1"> : </span>
      <span class="fw-700 fs-16 primary--text">
        {{ isLessTen(minutes) ? `0${minutes}` : minutes }}
      </span>
      <span class="fw-700 fs-16 primary--text mx-1"> : </span>
      <span class="fw-700 fs-16 primary--text">
        {{ isLessTen(seconds) ? `0${seconds}` : seconds }}
      </span>
    </div>
    <!-- // eslint-enable-next-line vue/no-parsing-error -->
    <v-spacer />
    <div class="box2" v-if="is_task_completed">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" color="red">
            mdi-clock-alert-outline
          </v-icon>
        </template>
        <span>Task is already completed</span>
      </v-tooltip>
    </div>
    <div
      class="box1"
      v-else-if="timer_belong_to_logged_user"
      @click="handle_timer"
    >
      <v-icon color="success" v-if="timer_started">pause</v-icon>
      <v-icon color="primary" v-else>play_arrow</v-icon>
    </div>
    <div v-else class="box1">
      <v-tooltip top color="error">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" color="error" v-if="timer_started">
            pause
          </v-icon>
          <v-icon v-bind="attrs" v-on="on" color="error" v-else>
            play_arrow
          </v-icon>
        </template>
        <span>
          Cannot start or stop timer, this timer is not assigned to you
        </span>
      </v-tooltip>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import request from '@/services/axios_instance'
import { v4 as uuidv4 } from 'uuid'

export default {
  inheritAttrs: false,
  name: 'TaskTimer',
  props: {
    item: Object,
    task: Object
  },
  data: () => ({
    timer_started: false,
    time_running: '00:00:00',
    interval: null,
    timer_status: null,
    is_timer_disabled: false,
    uuid: null,
    last_timer_action: ''
  }),
  watch: {
    item: {
      handler: function (val) {
        if (val && val.hasOwnProperty('last_timer')) {
          this.last_timer_action = val.last_timer ? val.last_timer.action : ''
        } else {
          this.last_timer_action = ''
        }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    ...mapGetters(['user']),
    is_task_completed() {
      return this.task && this.task.status.toLowerCase() === 'completed'
    },
    hours() {
      return this.time_running && parseInt(this.time_running.split(':')[0])
    },
    minutes() {
      return this.time_running && parseInt(this.time_running.split(':')[1])
    },
    seconds() {
      return this.time_running && parseInt(this.time_running.split(':')[2])
    },
    causer() {
      return this.item ? this.item.causer : null
    },
    timer_belong_to_logged_user() {
      return this.causer && this.user.id === this.causer.id
    },
    task_assigned_to_logged_user() {
      if (!this.task || !this.task.assigned.length) return false
      let index = this.task.assigned.findIndex((i) => i.id === this.user.id)
      return !!~index
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
    this.time_running = '00:00:00'
  },
  created() {
    this.uuid = uuidv4()
    if (this.item) {
      this.time_running = this.item.total_human
      this.timer_status = this.item.status
      if (this.timer_status === 'ongoing') {
        this.start_timer()
      }
    }
    this.$event.$on('task-timer-paused', (event) => {
      if (
        event.task === this.task.id &&
        event.user === this.causer.id &&
        this.uuid !== event.uuid
      ) {
        this.pause_dont_request_timer()
        this.last_timer_action = 'pause'
      }
    })
    this.$event.$on('task-timer-started', (event) => {
      if (
        event.task === this.task.id &&
        event.user === this.causer.id &&
        this.uuid !== event.uuid
      ) {
        this.start_timer()
        this.last_timer_action = event.action
      }
    })
  },
  methods: {
    isLessTen(number_or_string) {
      return parseInt(number_or_string) < 10
    },
    handle_timer() {
      if (this.is_timer_disabled) return
      if (this.timer_started) {
        //the user clicked pause
        this.pause_timer()
        this.$event.$emit('task-timer-paused', {
          task: this.task.id,
          user: this.user.id,
          id: this.uuid
        })
      } else {
        let action = this.last_timer_action === 'pause' ? 'back' : 'start'
        this.is_timer_disabled = true
        request
          .post(`api/task-timers/${this.task.id}/${action}`)
          .then(({ data }) => {
            this.start_timer()
            this.is_timer_disabled = false
            this.timer_status = 'ongoing'
            this.$event.$emit('task-timer-started', {
              task: this.task.id,
              user: this.user.id,
              id: this.uuid,
              action: action
            })
            this.appSnackbar('Task timer ongoing')
          })
      }
    },
    start_timer() {
      this.timer_started = true
      this.interval = setInterval(() => {
        let hours = this.hours
        let mins = this.minutes
        let secs = this.seconds
        if (secs >= 59 && mins >= 59) {
          hours++
          mins = secs = '00'
        } else if (secs >= 59) {
          mins++
          secs = '00'
        } else {
          secs++
        }
        this.set_time_running(hours, mins, secs)
      }, 1000)
    },
    pause_dont_request_timer() {
      clearInterval(this.interval)
      this.is_timer_disabled = false
      this.timer_status = 'paused'
      this.timer_started = false
    },
    pause_timer() {
      this.is_timer_disabled = true
      request.post(`api/task-timers/${this.task.id}/pause`).then(({ data }) => {
        this.pause_dont_request_timer()
        this.appSnackbar('Task timer paused')
      })
    },
    set_time_running(hours, mins, secs) {
      if (hours.toString().length === 1) hours = '0' + hours
      if (mins.toString().length === 1) mins = '0' + mins
      if (secs.toString().length === 1) secs = '0' + secs
      this.time_running = `${hours}:${mins}:${secs}`
    }
  }
}
</script>

<style lang="scss" scoped src="./HoursBox.scss"></style>
