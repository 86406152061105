<template>
  <v-autocomplete
    v-bind="$attrs"
    v-model="selected"
    :items="items"
    item-text="fullname"
    item-value="id"
    :rules="[requiredRule()]"
    chips
    hide-details="auto"
    clear-icon="mdi-close-circle-outline"
    small-chips
    :multiple="multiple"
  >
    <template v-slot:append v-if="hasAdd">
      <v-icon @click="$emit('click:add')">mdi-plus-circle-outline</v-icon>
    </template>
    <template v-slot:item="{ item }">
      <span>
        <v-list-item active-class="primary">
          <v-list-item-avatar>
            <v-img
              :lazy-src="settings.loader"
              contain
              :src="item.image_url"
            ></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="primary--text">
              {{ item.fullname | ucwords }}
            </v-list-item-title>
            <v-list-item-subtitle class="caption">
              {{ item.job_title | ucwords }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </span>
    </template>
    <template v-slot:selection="{ item, index }">
      <template v-for="(count, i) in displayCount">
        <v-chip label v-if="index === i" :key="count">
          <v-avatar size="20" class="mr-3">
            <v-img
              :lazy-src="settings.loader"
              contain
              :src="item.image_url"
            ></v-img>
          </v-avatar>
          <span class="primary--text">{{ item.first_name }}</span>
        </v-chip>
      </template>
      <v-chip label v-if="index === displayCount" class="primary--text caption">
        +{{ selected.length - displayCount }} others
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'KirbyUserPicker',
  props: {
    items: Array,
    value: Array,
    idsDontRemove: Array,
    hasAdd: { type: Boolean, default: false },
    multiple: { type: Boolean, default: true },
    displayCount: { type: Number, default: 2 },
    requiredCount: { type: Number, default: 0 }
  },
  data: () => ({
    selected: [],
    selection: [],
    hasError() {
      return this.requiredCount >= this.selected.length ? false : true
    }
  }),
  computed: {
    selectedUsers() {
      return _.cloneDeep(this.items).filter((u) => this.selected.includes(u.id))
    },
    selectionUsers() {
      return _.cloneDeep(this.items).filter(
        (u) => !this.selected.includes(u.id)
      )
    },
    rule() {
      if (this.requiredCount > 0) {
        return [
          (v) => !!v || 'This field is required',
          (v) =>
            (v && v.length >= this.requiredCount) ||
            `Please select atleast ${this.requiredCount} users`
        ]
      } else {
        return []
      }
    }
  },
  watch: {
    selected: {
      handler: function (val) {
        this.$emit('input', val)
      },
      deep: true,
      immediate: true
    },
    value: {
      handler: function (val) {
        this.selected = val
      },
      deep: true,
      immediate: true
    },
    items: {
      handler: function (val) {
        this.selection = _.cloneDeep(val)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    toggleSelected(user) {
      let index = this.selected.findIndex((u) => u === user.id)
      if (~index) {
        this.selected.splice(index, 1)
      } else this.selected.push(user.id)
    },
    requiredRule() {
      if (this.requiredCount === 0) {
        return true
      }
      return (v) =>
        (v && v.length >= this.requiredCount) ||
        `Please select atleast ${this.requiredCount}`
    }
  }
}
</script>

<style lang="scss" scoped></style>
