<template>
  <CustomDialog
    :has-footer="false"
    :max-width="800"
    :open.sync="open"
    :title="dialogTitle"
    @click-close="close"
  >
    <template #content>
      <v-card flat v-if="item">
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="10" class="mb-2">
              <h1 class="title text-left">{{ item.title | ucwords }}</h1>
            </v-col>
            <v-spacer />
            <v-menu offset-y left>
              <template v-slot:activator="{ on: menu }">
                <v-tooltip top>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-icon v-on="{ ...tooltip, ...menu }">
                      mdi-dots-horizontal-circle-outline
                    </v-icon>
                  </template>
                  <span>Actions</span>
                </v-tooltip>
              </template>
              <v-list dense>
                <v-list-item :disabled="item.histories.length === 0">
                  <v-list-item-title>
                    <v-icon color="grey" left>mdi-history</v-icon>
                    View Edit Request History
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="
                    item.status.toLowerCase() !== 'completed' &&
                    can_edit_request
                  "
                  @click="request_action('request-edit')"
                >
                  <v-list-item-title>
                    <v-icon color="grey" left>
                      mdi-file-document-edit-outline
                    </v-icon>
                    Edit Request
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="
                    item.status.toLowerCase() === 'approved' &&
                    (user.is_admin || user.is_manager)
                  "
                  @click="request_action('request-mark-as-completed')"
                >
                  <v-list-item-title>
                    <v-icon color="grey" left>mdi-clipboard-check</v-icon>
                    Mark as Completed
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="
                    item.status.toLowerCase() === 'pending' &&
                    (can_edit_request || user.is_admin || user.is_manager)
                  "
                  @click="request_action('request-mark-as-archived')"
                >
                  <v-list-item-title>
                    <v-icon color="grey" left>mdi-clipboard-arrow-down</v-icon>
                    Archived Request
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="
                    item.status.toLowerCase() === 'pending' &&
                    (user.is_admin || user.is_manager)
                  "
                  @click="request_action('request-mark-as-approved')"
                >
                  <v-list-item-title>
                    <v-icon color="grey" left>mdi-clipboard-account</v-icon>
                    Approved Request
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-row>
          <v-row no-gutters>
            <v-col cols="4">
              <h5 class="text-left subtitle-2 mb-1">Requested By:</h5>
              <avatar :user="item.requester" />
            </v-col>
            <v-col cols="5">
              <h5 class="text-left subtitle-2 mb-1" v-if="item.project">
                Project/Campaign:
              </h5>
              <v-chip class="border-0" label outlined v-if="item.project">
                <v-icon left large>mdi-content-paste</v-icon>
                {{ item.project.title | ucwords }}
              </v-chip>
            </v-col>
            <v-col cols="3">
              <h5 class="text-left subtitle-2 mb-1">Status:</h5>
              <v-chip class="border-0" label outlined>
                <v-icon
                  color="green"
                  left
                  large
                  v-if="item.status.toLowerCase() === 'completed'"
                >
                  mdi-clipboard-check
                </v-icon>
                <v-icon
                  color="blue"
                  left
                  large
                  v-else-if="item.status.toLowerCase() === 'approved'"
                >
                  mdi-clipboard-account
                </v-icon>
                <v-icon
                  color="grey"
                  left
                  large
                  v-else-if="item.status.toLowerCase() === 'archived'"
                >
                  mdi-clipboard-arrow-down
                </v-icon>
                <v-icon
                  color="red"
                  left
                  large
                  v-else-if="item.status.toLowerCase() === 'pending'"
                >
                  mdi-clipboard-alert
                </v-icon>
                {{ item.status | ucwords }}
              </v-chip>
            </v-col>
            <v-col cols="12" class="mt-2">
              <h5 class="text-left subtitle-2 mb-1">Details:</h5>
              <Editor
                v-if="item.contents && item.contents.trim() !== ''"
                :value="item.contents"
                :editable="false"
              ></Editor>
              <Response
                v-if="item.props && item.props.structures"
                :items="item.props.structures"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </CustomDialog>
</template>
<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import Editor from '@/common/Editor/Editor.vue'
import { mapGetters } from 'vuex'
import Response from '../../../Forms-Builder/components/Builder/Response.vue'

export default {
  name: 'RequestViewDialog',
  components: {
    Response,
    Editor,
    CustomDialog
  },
  props: {
    item: Object,
    dialog: { type: Boolean, default: false }
  },
  data: () => ({
    open: false
  }),
  watch: {
    dialog(val) {
      this.open = val
    },
    open(new_val) {
      this.$emit('update:dialog', new_val)
    }
  },
  computed: {
    ...mapGetters(['user']),
    dialogTitle() {
      return this.item ? this.item.title : 'View Request'
    },
    can_edit_request() {
      return !!(this.item && this.user.id === this.item.requested_by)
    }
  },
  methods: {
    close() {
      this.open = false
      this.$emit('close', false)
    },
    request_action(action) {
      this.$emit(action, this.item)
      if (action === 'request-edit') {
        this.close()
      }
    }
  }
}
</script>
