<template>
  <v-card flat class="personal-tasks-tab rounded-10">
    <v-card-text>
      <Split
        style="min-height: 100vh"
        :direction="!mdAndUp ? 'vertical' : 'horizontal'"
      >
        <SplitArea :size="45" :min-size="350">
          <TasksCard
            :project="null"
            @show-task="setActiveTask"
            label="My Tasks"
            :has-milestones="false"
            :has-assigning="false"
            :has-add-button="hasAddButton"
            show-project
            private
          ></TasksCard>
        </SplitArea>
        <SplitArea :size="55" :min-size="350">
          <TaskDetails
            v-if="activeTask"
            :task="activeTask"
            :has-milestones="false"
            :has-assigning="false"
            show-project
            @task-details-closed="setActiveTask(null)"
          ></TaskDetails>
          <Empty
            style="min-height: 500px"
            icon="mdi-clipboard-arrow-left"
            v-else
            headline="No selected task"
          ></Empty>
        </SplitArea>
      </Split>
    </v-card-text>
  </v-card>
</template>
<script>
import TasksCard from '@/modules/ProjectPreview-Tasks/components/TasksCard.vue'
import TaskDetails from '@/modules/ProjectPreview-Tasks/components/TaskDetails.vue'
import TasksContent from '@/common/TasksCard/TasksContent.vue'
import TaskParticipantsDialog from '@/modules/ProjectPreview-Tasks/components/TaskParticipantsDialog/TaskParticipantsDialog.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'PersonalTask',
  components: {
    TasksCard,
    TaskDetails,
    TaskParticipantsDialog,
    TasksContent
  },

  props: {
    id: [Number, String]
  },

  created() {
    this.set_project_id(null)
    this.set_user_id(this.view_user.id)
    this.set_activeFilter({ text: 'My All Tasks', value: 'my_tasks' })
    this.get_personal_tasks({ for_user: this.view_user.id })
  },
  computed: {
    ...mapGetters(['user']),
    ...mapGetters('memberProfile', ['view_user']),
    ...mapGetters('taskCards', ['is_fetching', 'activeTask']),
    hasAddButton() {
      return this.user.id === this.view_user.id
    }
  },
  methods: {
    ...mapMutations('taskCards', [
      'set_project_id',
      'set_user_id',
      'set_activeTask',
      'set_activeFilter'
    ]),
    ...mapActions('taskCards', [
      'get_personal_tasks',
      'load_more_personal_tasks'
    ]),
    setActiveTask(task) {
      this.set_activeTask(task)
    }
  }
}
</script>
<style lang="scss" scoped>
.personal-tasks-tab {
  display: grid;
  border-radius: 15px;
  padding: 15px;
}
</style>
