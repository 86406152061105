<template>
  <v-card flat class="taskscard">
    <v-card-title>
      <v-row dense align="center" class="mb-1" v-if="withTitle">
        <h2 class="task-card-label fw-700 primary--text">
          {{ label | ucwords }}
        </h2>
      </v-row>
      <v-row
        dense
        align="center"
        justify="space-between"
        :class="mdAndUp ? 'flex-nowrap' : 'flex-wrap'"
      >
        <slot name="top-left"> </slot>
        <v-btn
          height="40"
          v-if="hasAddButton"
          depressed
          @click="createTask"
          class="add-button rounded-10 text-none ml-1 mb-1"
          color="primary"
        >
          <v-icon color="white">mdi-plus</v-icon>
        </v-btn>
        <cMenu
          :label="activeFilter.text"
          dark
          depressed
          close-on-content-click
          left-icon="mdi-filter"
          color="primary"
          btn-style="height:40px;"
          btn-class="mx-1 mb-1 rounded-10"
        >
          <v-list dense>
            <template v-for="(filt, index) in filters">
              <v-divider
                v-if="filt.text === 'divider'"
                :key="index"
              ></v-divider>
              <v-list-item v-else :key="index" @click="filterTask(filt)">
                <v-list-item-title> {{ filt.text }} </v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </cMenu>
        <v-autocomplete
          hide-details="auto"
          dense
          class="searchbox mb-1"
          :items="search_results"
          :loading="is_searching"
          v-model="searchbox"
          :search-input.sync="search_word"
          clearable
          cache-items
          item-text="title"
          placeholder="Start typing to Search"
          prepend-inner-icon="search"
          outlined
          append-icon="mdi-chevron-down"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                Search for <strong>Task</strong>
              </v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item @click="setActiveTask(item)">
              <v-list-item-content>
                <v-list-item-title class="primary--text">
                  <span>{{ item.title | truncate(25) | ucwords }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>
        <slot name="top-right"></slot>
      </v-row>
    </v-card-title>
    <v-card-text class="card-body">
      <v-row dense class="table-header" align="center" justify="center">
        <v-col cols="7">
          <span class="subtitle-2 pl-5 fw-700 white--text"> Task </span>
        </v-col>
        <v-col cols="3" class="text-center">
          <span class="mx-auto subtitle-2 fw-700 white--text"> Status </span>
        </v-col>
        <v-col cols="2" class="text-center">
          <span class="mx-auto subtitle-2 fw-700 white--text"> Action </span>
        </v-col>
      </v-row>
      <OverlayLoader :value="is_fetching"></OverlayLoader>
      <template v-if="tasks.length > 0">
        <v-hover
          v-slot:default="{ hover }"
          v-for="task in tasks"
          :key="task.id"
        >
          <v-row
            class="per-task"
            justify="center"
            align="center"
            dense
            :class="{ active: is_active_task(task) }"
            @click.stop="setActiveTask(task)"
          >
            <v-col cols="7" class="subtitle-2 fw-700 pl-3">
              <div>
                <span>{{ task.title | ucwords | truncate(45) }}</span>
              </div>
              <div v-if="showProject">
                <span class="caption" v-if="task.project">
                  {{ task.project.project_name }}
                </span>
                <span class="caption" v-else>Personal Task</span>
              </div>
            </v-col>
            <v-col cols="3" class="subtitle-2 fw-700">
              <v-chip
                small
                style="display: inline-block"
                :class="
                  hover ? 'white primary--text' : `${task.status} light--text`
                "
                pill
                class="status-chips"
              >
                {{ task.status | ucwords }}
              </v-chip>
            </v-col>
            <v-col cols="2" class="text-center">
              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    dark
                    class="mx-auto"
                    icon
                    :color="hover ? 'white' : 'primary'"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>

                <v-list dense class="whitesmoke">
                  <v-list-item
                    v-for="(action, i) in taskActions(task)"
                    :key="i"
                    @click="handleTaskAction(action, task)"
                  >
                    <v-list-item-icon class="mr-2">
                      <v-icon color="primary">{{ action.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="primary--text">
                      {{ action.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-hover>
      </template>
      <Empty
        v-else-if="!is_fetching"
        :headline="empty_headline"
        :icon="empty_icon"
      ></Empty>
    </v-card-text>
    <v-card-actions>
      <v-btn
        v-if="hasMoreTask"
        :disabled="is_fetching"
        :loading="is_fetching"
        depressed
        text
        @click="loadMoreTask"
        class="text-none primary"
        block
        small
      >
        Load more
      </v-btn>
      <v-btn
        v-else
        :disabled="true"
        depressed
        text
        class="text-none"
        block
        small
      >
        No more
      </v-btn>
    </v-card-actions>

    <TaskDialog
      v-model="task_dialog"
      @save="saveTask"
      @update="updateTask"
      @create-milestone="create_milestone_dialog = true"
      :item="activeTask"
      :milestones="milestones"
      :milestone="activeMilestone"
      :has-milestones="hasMilestones"
      :has-assigning="hasAssigning"
      :project-id="project_id"
      :user-id="user_assigned"
    ></TaskDialog>

    <MilestoneTabDialog
      v-model="create_milestone_dialog"
      @save="addNewMilestone"
    ></MilestoneTabDialog>

    <DeleteDialog
      text-content="Are you sure you want to delete this task?"
      v-model="delete_task_dialog"
      @delete="deleteTask"
    ></DeleteDialog>
  </v-card>
</template>

<script>
import TaskDialog from '@/common/Dialogs/TaskDialog.vue'
import MilestoneTabDialog from '@/modules/ProjectPreview-Milestones/components/MilestoneTabDialog/MilestoneTabDialog.vue'
import { mapActions, mapGetters } from 'vuex'
import DeleteDialog from '@/common/DeleteDialog.vue'
import { task_mixins } from '@/modules/ProjectPreview-Tasks/task_mixins'
import _ from 'lodash'

export default {
  name: 'TasksCard',
  mixins: [task_mixins],
  components: {
    TaskDialog,
    DeleteDialog,
    MilestoneTabDialog
  },
  props: {
    hasMilestones: { type: Boolean, default: true },
    hasAssigning: { type: Boolean, default: true },
    showProject: { type: Boolean, default: false },
    withTitle: { type: Boolean, default: true },
    project: { type: Object, default: null },
    label: { type: String, default: 'Tasks' },
    private: { type: Boolean, default: false }, //private for personal tasks
    activeId: { type: [Number, String], default: null },
    hasAddButton: { type: Boolean, default: true }
  },
  data: () => ({
    search_word: '',
    searchbox: null
  }),
  computed: {
    ...mapGetters(['user']),
    ...mapGetters('taskCards', [
      'project_tasks',
      'personal_tasks',
      'activeFilter',
      'is_fetching',
      'is_searching',
      'search_results'
    ]),
    project_id() {
      return this.project ? this.project.id : null
    },
    user_assigned() {
      return this.private ? this.user.id : null
    },
    activeTask: {
      get() {
        return this.$store.getters['taskCards/activeTask']
      },
      set(val) {
        this.$store.commit('taskCards/set_activeTask', val)
      }
    },
    empty_icon() {
      return this.activeFilter.value === 'all_tasks'
        ? 'mdi-clipboard-alert'
        : 'mdi-filter'
    },
    empty_headline() {
      return this.activeFilter.value === 'all_tasks'
        ? `No tasks yet.`
        : `No tasks yet for selected filter`
    },
    filters() {
      if (this.private) {
        return this.$store.getters['taskCards/myTaskFilters']
      } else return this.$store.getters['taskCards/taskFilters']
    },
    hasMoreTask() {
      if (this.private) {
        return this.$store.getters['taskCards/project_tasks_next_page_url']
          ? true
          : false
      } else {
        return this.$store.getters['taskCards/personal_tasks_next_page_url']
          ? true
          : false
      }
    },
    tasks() {
      return this.private ? this.personal_tasks : this.project_tasks
    },
    isFetching() {
      return this.$store.getters['taskCards/is_fetching']
    }
  },
  watch: {
    activeId(val) {
      if (val) {
        let activeTask = this.tasks.find((task) => task.id === val)
        this.setActiveTask(activeTask)
      }
    },
    search_word: _.debounce(function (val) {
      this.search_tasks({
        type: this.private ? 'personal' : 'project',
        search: val
      })
    }, 500)
  },
  methods: {
    ...mapActions('taskCards', [
      'load_more_project_tasks',
      'load_more_personal_tasks',
      'get_project_tasks',
      'get_personal_tasks',
      'search_tasks'
    ]),
    filterTask(filter) {
      this.$store.commit('taskCards/set_activeFilter', filter)
      if (this.private) {
        this.get_personal_tasks({
          filter: filter.value
        })
      } else {
        this.get_project_tasks({
          filter: filter.value
        })
      }
    },
    is_active_task(task) {
      return this.activeTask && this.activeTask.id === task.id
    },
    loadMoreTask() {
      if (this.private) {
        this.load_more_personal_tasks()
      } else {
        this.load_more_project_tasks()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.taskscard {
  .task-card-label {
    font-size: 25px;
  }
  .table-header {
    margin-top: 10px;
    padding: 5px 0px;
    background-color: #223a6b;
  }
  .card-body {
    min-height: 500px;
    max-height: 1000px;
    overflow-y: auto;
    .per-task {
      padding-top: 5px;
      padding-bottom: 5px;
      background: #e8ebf0;
      border-bottom: 1px solid white;
      cursor: pointer;
      span {
        color: $blue;
      }
      &:hover {
        background-color: $blue;
        border-radius: 5px;
        span {
          color: white;
        }
        .v-icon {
          color: white;
        }
      }
      &.active {
        background-color: white;
        border-radius: 5px;
        span {
          color: $blue;
        }
        .v-icon {
          color: $blue;
        }
      }
      .status-chips {
        width: 90% !important;
        text-align: center !important;
        color: whitesmoke;
        background: unset;
        &.Urgent {
          color: $urgent !important;
          font-size: 14px;
          font-weight: 700;
          background: unset;
        }
        &.Pending {
          color: $pending !important;
          font-size: 14px;
          font-weight: 700;
          background: unset;
        }
        &.Behind {
          color: $behind !important;
          font-size: 14px;
          font-weight: 700;
          background: unset;
        }
        &.Open {
          color: $open !important;
          font-size: 14px;
          font-weight: 700;
          background: unset;
        }
        &.Completed {
          color: $completed !important;
          font-size: 14px;
          font-weight: 700;
          background: unset !important;
        }
        &.completed {
          color: $completed !important;
          font-size: 14px;
          font-weight: 700;
          background: unset !important;
        }
      }
    }
  }
}
</style>
