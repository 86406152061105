<template>
  <v-card flat class="tasks-details">
    <OverlayLoader :value="loading"></OverlayLoader>
    <v-card-title>
      <v-row dense align="center" justify="center">
        <v-col cols="12">
          <h2 class="header-title primary--text">
            {{ task.title | ucwords }}
          </h2>
        </v-col>
        <!-- <v-col cols="2">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                class="mx-auto"
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="primary">mdi-cog</v-icon>
              </v-btn>
            </template>

            <v-list dense class="whitesmoke">
              <v-list-item
                v-for="(action, i) in taskActions(task)"
                :key="i"
                @click="handleTaskAction(action, task)"
              >
                <v-list-item-icon class="mr-2">
                  <v-icon color="primary">{{ action.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="primary--text">
                  {{ action.text }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn icon @click="$emit('task-details-closed')">
            <v-icon color="primary">mdi-close</v-icon>
          </v-btn>
        </v-col> -->
      </v-row>
    </v-card-title>
    <v-card-text class="card-body">
      <v-row dense align="center" justify="space-between">
        <v-chip
          pill
          small
          class="px-10 rounded-4 py-4 status-chips font-weight-bold"
          :class="task.status"
        >
          {{ task.status | ucwords }}
        </v-chip>
        <v-chip
          pill
          small
          class="px-10 primary rounded-4 py-4 status-chips font-weight-bold"
        >
          {{ task.created_at | format('LL') }}
        </v-chip>
      </v-row>
      <v-row dense>
        <v-col cols="12" class="mt-5">
          <label class="title font-weight-bold primary--text">
            Description
          </label>
          <Editor
            class="body-2 description-wrapper"
            v-if="task.description"
            :value="task.description"
            :editable="false"
          ></Editor>
          <Empty v-else headline="No description"></Empty>
        </v-col>
        <v-col :cols="mdAndUp ? 12 : 12" class="mt-5">
          <v-row dense align="center" class="mb-2" justify="space-between">
            <label class="title font-weight-bold primary--text">
              Assigned to :
            </label>
            <v-chip
              v-if="task_timers"
              pill
              small
              class="
                px-1
                transparent
                ls-2
                primary--text
                rounded-4
                py-4
                status-chips
                font-weight-bold
              "
            >
              ~Total:
              <span class="ml-2 ls-2"> {{ task_timers.total_human }} </span>
            </v-chip>
          </v-row>
          <template v-if="task_timers && task_timers.summary.length">
            <TaskTimer
              v-for="timer in task_timers.summary"
              :key="`${task.id}-${timer.causer.id}`"
              :item="timer"
              class="mb-1"
              :task="task"
            ></TaskTimer>
          </template>
          <v-chip
            v-else
            large
            label
            outlined
            class="no-border d-block mt-1 primary--text px-10"
          >
            <v-icon left large class="mr-3">mdi-account-off</v-icon> Unassigned
          </v-chip>
        </v-col>
        <v-col :cols="mdAndUp ? 6 : 12" class="mt-5">
          <label class="title font-weight-bold primary--text">
            Milestone :
          </label>
          <v-chip
            large
            label
            v-if="task.milestone"
            outlined
            class="no-border d-block mt-1 primary--text"
          >
            {{ task.milestone.title | ucwords }}
          </v-chip>
          <v-chip
            large
            label
            v-else
            outlined
            class="no-border d-block mt-1 primary--text"
          >
            No milestone attach
          </v-chip>
        </v-col>
        <v-col :cols="12" class="mt-5" v-if="showProject">
          <label class="title font-weight-bold primary--text">
            Project :
          </label>
          <v-chip
            large
            label
            @click="navigateProject"
            v-if="task.project"
            outlined
            class="cursor-pointer no-border d-block mt-1 primary--text"
          >
            {{ task.project.title | ucwords }}
          </v-chip>
          <v-chip
            large
            label
            v-else
            outlined
            class="no-border d-block mt-1 primary--text"
          >
            No project attach
          </v-chip>
        </v-col>
        <v-col cols="12" class="mt-5">
          <Comment :id="task.id" type="task">
            <template v-slot:label>
              <label class="title font-weight-bold primary--text">
                Comments :
              </label>
            </template>
          </Comment>
        </v-col>
      </v-row>
    </v-card-text>

    <TaskDialog
      v-model="task_dialog"
      @save="saveTask"
      @update="updateTask"
      @create-milestone="create_milestone_dialog = true"
      :item="activeTask"
      :milestones="milestones"
      :milestone="activeMilestone"
      :has-milestones="hasMilestones"
      :has-assigning="hasAssigning"
    ></TaskDialog>
    <DeleteDialog
      text-content="Are you sure you want to delete this task?"
      v-model="delete_task_dialog"
      @delete="deleteTask"
    ></DeleteDialog>
  </v-card>
</template>

<script>
import Editor from '@/common/Editor/Editor.vue'
import Comment from '@/common/Comment/Comment.vue'
import TaskTimer from '@/common/HoursBox/TaskTimer.vue'
import request from '@/services/axios_instance'
import TaskDialog from '@/common/Dialogs/TaskDialog.vue'
import DeleteDialog from '@/common/DeleteDialog.vue'
import { task_mixins } from '@/modules/ProjectPreview-Tasks/task_mixins'
export default {
  name: 'TaskDetails',
  mixins: [task_mixins],
  components: {
    Editor,
    Comment,
    TaskTimer,
    TaskDialog,
    DeleteDialog
  },
  props: {
    task: Object,
    hasMilestones: { type: Boolean, default: true },
    hasAssigning: { type: Boolean, default: true },
    showProject: { type: Boolean, default: false }
  },
  data: () => ({
    loading: false,
    showTimer: false,
    task_timers: null
  }),
  watch: {
    task: {
      handler: function (val) {
        if (val) {
          this.fetchTaskTimers(val)
        } else {
          this.task_timers = null
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    fetchTaskTimers(task) {
      this.loading = true
      request
        .get(`api/task/${task.id}/timers`)
        .then(({ data }) => {
          this.task_timers = data
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false
          }, 100)
        })
    },
    navigateProject() {
      this.$router.push({
        name: 'hq',
        params: {
          id: this.task.project_id,
          type: 'project'
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tasks-details {
  min-height: 100%;
  .header-title {
    font-size: 25px !important;
    font-weight: 900;
  }
  .no-border {
    border: none !important;
  }
  .status-chips {
    text-align: center !important;
    color: whitesmoke;

    &.Urgent {
      background-color: $urgent;
    }
    &.Pending {
      background-color: $pending;
    }
    &.Behind {
      background-color: $behind;
    }
    &.Open {
      background-color: $open;
    }
    &.completed {
      background-color: $completed;
    }
  }
}
</style>
