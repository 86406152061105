<template>
  <CustomDialog
    :has-footer="false"
    :max-width="800"
    :open.sync="open"
    :title="dialogTitle"
    @click-close="close"
  >
    <template #content>
      <v-card flat v-if="item && item.histories">
        <v-expansion-panels v-model="panel" multiple focusable hover>
          <v-expansion-panel
            v-for="history in item.histories"
            :key="history.id"
          >
            <v-expansion-panel-header>
              <span>
                <v-icon left>mdi-clipboard-check</v-icon>
                <strong>{{ history.created_at | format('lll') }}</strong>
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <Editor
                v-if="history.contents && history.contents.trim() !== ''"
                :value="history.contents"
                :editable="false"
              ></Editor>
              <Response
                v-if="history.props && history.props.structures"
                :items="history.props.structures"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </template>
  </CustomDialog>
</template>

<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import Editor from '@/common/Editor/Editor.vue'
import Response from '../../../Forms-Builder/components/Builder/Response.vue'

export default {
  name: 'RequestEditHistoryDialog',
  components: {
    Response,
    CustomDialog,
    Editor
  },
  props: {
    item: Object,
    dialog: { type: Boolean, default: false }
  },
  data: () => ({
    open: false,
    panel: []
  }),
  computed: {
    dialogTitle() {
      return this.item
        ? `Edit History (${this.item.title})`
        : 'Edit Request History'
    }
  },
  watch: {
    dialog(val) {
      this.open = val
    },
    open(new_val) {
      this.$emit('update:dialog', new_val)
    },
    item: {
      handler(val) {
        if (val) {
          this.panel = [...Array(this.item.histories).keys()].map((k, i) => i)
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    close() {
      this.open = false
      this.$emit('close', false)
    }
  }
}
</script>

<style scoped></style>
