import { render, staticRenderFns } from "./TaskCustomTable.vue?vue&type=template&id=2b28a7c8&scoped=true&"
import script from "./TaskCustomTable.vue?vue&type=script&lang=js&"
export * from "./TaskCustomTable.vue?vue&type=script&lang=js&"
import style0 from "./TaskCustomTable.vue?vue&type=style&index=0&id=2b28a7c8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b28a7c8",
  null
  
)

export default component.exports